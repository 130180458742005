import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import Divider from '../components/divider';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../components/css/what-we-do.css';

const WhatWeDoPage = ({data}) => {
  return (
    <Layout>
      <SEO title="What We Do" description="Pauly Group provides full search or individual services like search preparation, recruiting, board assistance, applicant and candidate contact, referencing." />
      <div className="whatwedo -container">
        <div className="whatwedo -header">
          <h1>What We Do</h1>
          <Divider />
        </div>
        <div className="whatwedo -candidates">
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={12} md={6}>
              <Image fluid={data.whatwedoCandidatesImage.childImageSharp.fluid} />
            </Grid>
            <Grid item sm={12} md={6}>
              <h2 className="whatwedo -section-header">Pauly Group Finds the Right Candidates, the Right Way.</h2>
              <p>Does your community or technical college need to find a new president
              or senior administrator? Searching for the right candidate can feel like a
              daunting task, but it really doesn't have to be. You just have to work with
              an academic search firm with a time-tested process for success, like Pauly Group.</p>
            </Grid>
          </Grid>
        </div>
        <div className="whatwedo -process">
          <Grid container spacing={0} alignItems="center">
            <Grid item sm={12} md={6}>
              <h1 className="whatwedo -section-header">Our Process</h1>
              <p>Pauly Group has been providing community and technical colleges
              with ideal candidates for more than 25 years. Through careful planning
              and our established process, we can help you find the leader
              to fit your organization and help it thrive and succeed.</p>
              <p>Our clients appreciate the fact that our process is personalized,
              smooth, and professional. This process creates a foundation of
              success for the person hired in the search.</p>
              <p>Pauly Group President Angela Provart is personally involved with
              every search we do. She works closely with a team of consultants every
              step of the way and is always available to our valued clients.</p>
              <p>Click on each step below for a closer look at what you can expect
              when you work with Pauly Group. Your college can contract for a full
              search or for individual services like recruiting or referencing.</p>
            </Grid>
            <Grid item sm={12} md={6}>
              <Image fluid={data.whatwedoProcessImage.childImageSharp.fluid} />
            </Grid>
          </Grid>
        </div>
        <ExpansionPanel className = "whatwedo -accordion">
          <ExpansionPanelSummary className="whatwedo -accordion-header" expandIcon={<ExpandMoreIcon className="whatwedo -accordion-icon" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="whatwedo -accordion-header">SEARCH PREPARATION</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="whatwedo -accordion-expand">
              <li>Through personal conversations and explanations of the
              search process, Pauly Group will establish trust with your
              trustees, college leaders, staff and faculty. Our team will
              provide guidance to your Board from the beginning of the search
              to transitioning process.</li>
              <li>Pauly Group will help you determine the qualifications and
              characteristics needed for any administrative position at your
              community college.</li>
              <li>We will develop a search timeline and position profile,
              including minimum qualifications, desired characteristics,
              challenges, and opportunities.</li>
              <li>We’ll conduct discussions with internal and external
              stakeholders in order to gain insight on the needs of the
              college.</li>
              <li>We’ll provide guidance and advice for the development of
              your search committee and coordinate meeting schedules.</li>
              <li>Your internal and external stakeholders will receive detailed
              reports about the search process.</li>
              <li>Your team will receive clear and consistent status reports,
              both verbally and via email.</li>
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className = "whatwedo -accordion">
          <ExpansionPanelSummary className="whatwedo -accordion-header" expandIcon={<ExpandMoreIcon className="whatwedo -accordion-icon" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="whatwedo -accordion-header">APPLICANT RECRUITING</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="whatwedo -accordion-expand">
              <li>We will develop and execute a strategic targeted
              advertising campaign to reach candidates with diverse backgrounds
              that meet your qualifications. The campaign will include personal
              recruitment, social media and traditional advertising channels.</li>
              <li>We will organize the maintenance of the search page on your website.</li>
              <li>Our team will create and distribute various announcements to reach approximately 1000 potential applicants for your position.  This is done via email, text message and personal phone calls.</li>
              <li>We will protect the confidentiality of all candidate information.</li>
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className = "whatwedo -accordion">
          <ExpansionPanelSummary className="whatwedo -accordion-header" expandIcon={<ExpandMoreIcon className="whatwedo -accordion-icon" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="whatwedo -accordion-header">BOARD AND COMMITEE ASSISTANCE</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="whatwedo -accordion-expand">
              <li>Pauly Group will facilitate the development of candidate
              selection criteria with your Board.</li>
              <li>We will advise your Board and Search Committee about the
              application review and interview techniques.</li>
              <li>We’ll assist your Board/Search Committee with the
              development of interview questions.</li>
              <li>Our team will facilitate candidate interviews with your
              Board/Search Committee.</li>
              <li>We’ll conduct thorough reference and background
              investigations on candidates and provide written reference
              reports. This referencing includes extensive interviews with
              up to eight listed and unlisted references.</li>
              <li>Our team will conduct the preliminary review of applicant
              qualifications.</li>
              <li>Pauly Group will provide your team with a deep understanding
              of the strengths and weaknesses of your candidates.</li>
              <li>Printed materials will be provided with applicant rankings
              to assist with the selection process.</li>
              <li>We’ll coordinate Board/Committee discussions regarding the
              final decision.</li>
              <li>We’ll negotiate the contract between your Board and the
              selected candidate.</li>
              <li>We’ll facilitate the plan for presidential or senior
              administrator’s transition.</li>
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className = "whatwedo -accordion">
          <ExpansionPanelSummary className="whatwedo -accordion-header" expandIcon={<ExpandMoreIcon className="whatwedo -accordion-icon" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="whatwedo -accordion-header">APPLICANT AND CANDIDATE CONTACT</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="whatwedo -accordion-expand">
              <li>Pauly Group will maintain frequent and effective
              communication with applicants in order to keep them engaged in
              the process.</li>
              <li>Our team will coordinate and schedule candidate interviews.</li>
              <li>We’ll obtain candidate permission to conduct thorough
              reference and background investigations.</li>
              <li>We’ll provide stringent protection of applicant confidentiality.</li>
              <li>We’ll notify all nominators and applicants of the Board’s
              final selection in a prompt and professional manner.</li>
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className = "whatwedo -accordion">
          <ExpansionPanelSummary className="whatwedo -accordion-header" expandIcon={<ExpandMoreIcon className="whatwedo -accordion-icon" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="whatwedo -accordion-header">REFERENCING</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="whatwedo -accordion-expand">
              <li>Our referencing is a full 360 degree view of candidates
              based on extensive interviews with sources.</li>
              <li>We interview between 8 and 10 sources for each candidate
              we reference, including unlisted sources.</li>
              <li>We provide an experienced Project Manager to consult with
              hiring managers on position requirements, discuss concerns about
              potential candidates, and answer questions during or after the
              reference process.</li>
              <li>We’ll write reference interview questions that are specific
              to your college and position.</li>
              <li>We provide comprehensive, written, reference reports for
              your decision making.</li>
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel className = "whatwedo -accordion">
          <ExpansionPanelSummary className="whatwedo -accordion-header" expandIcon={<ExpandMoreIcon className="whatwedo -accordion-icon" />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="whatwedo -accordion-header">TECHNOLOGY</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="whatwedo -accordion-expand">
              <li>From our beginning, Pauly Group has set the standard by
              incorporating the latest technology into the search process.
              By using the latest communications tools and technologies,
              searches for Presidents and key administrators are more
              efficient and affordable.</li>
              <li>We provide online reading and discussion rooms, which
              provide greater efficiency and save time for our clients.</li>
              <li>Decision makers can access confidential reference reports
              through our secure website.</li>
              <li>Our videoconferencing services are used for interviews and
              search committee meetings. These provide enable committee member
              the opportunity to meet a larger, more diverse candidate pool
              and also significant savings in consultant and semi-final candidate travel.</li>
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    whatwedoCandidatesImage: file(relativePath: {eq: "what-we-do-candidates.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    whatwedoProcessImage: file(relativePath: {eq: "what-we-do-process.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default WhatWeDoPage
